import React, { useState, useEffect } from 'react'
import './styles.css'
import { Element } from "react-scroll";
import { connect } from 'react-redux'
import Navbar from './../components/navbarContacto'
import Modal from '../components/modal'
import { getMediaByUsername } from 'nanogram.js';
import { Helmet } from "react-helmet"
import Timer from "../components/Countdown/Timer";

function Home(props) {
    const [data, setData] = useState({})
    const [modal, setModal] = useState(false)
    const [photos, setPhotos] = useState([{ key: 1 }, { key: 2 }, { key: 3 }, { key: 4 }, { key: 5 }, { key: 6 }])
    let slideIndex = 0;
    const handleService = (data) => {
        setData(data)
        setModal(true)
    }
    const slider = () => {
        let i;
        var slides = document.getElementsByClassName("heroImagen");
        for (i = 0; i < slides.length; i++) {
            slides[i].style.display = "none";
        }
        slideIndex++;
        if (slideIndex > slides.length) { slideIndex = 1 }
        slides[slideIndex - 1].style.display = "block";
    }

    useEffect(async () => {
        setInterval(() => slider(), 5000);
        document.getElementById('slogan').innerHTML = props.data.Slogan;
        document.getElementById('slogan').style.fontSize = props.data.sizeSlogan;

    }, [])

    const handleShare = async (e, url) => {
        // Evitamos el comportamiento por default del enlace
        e.preventDefault();
        const shareData = {
            url: url,
        }
        await navigator.share(shareData)
    }

    return (
        
        <div className="wrappContainer" style={{ backgroundColor: `#${props.data.ColorDegradado}` }}>
            <Helmet>
                <title>{props.data.titleHtml}</title>
                <link rel="icon" type="image/png" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.Favicon}`} sizes="64x64" />
                <meta name="description" content={window.subdominio} />
                <meta name="theme-color" content={`#${props.data.ColorDegradado}`} />
                <link rel="shortcut icon" sizes="192x192" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.UrlLogoApp}`} />
                <link rel="shortcut icon" sizes="128x128" href={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}/${props.data.UrlLogoApp}`} />
            </Helmet>
            <div className="section hero" id="Section1">
                {props.data.TotalSlider.filter(gallery => gallery.tipo === 1).map(datos => {
                    console.log("Entro Slider");
                    if (props.data.TotalSlider.length == 1) {
                        return (
                            <div className="heroImagen" key={datos} style={{backgroundImage: `url("https://plataforma.greencardpv.com/solicitudes/${window.subdominio}${window.carpeta}/${datos.url_logo}")`}}></div>
                        )
                    } else {
                        return (
                            <div className="heroImagen" key={datos} style={{backgroundImage: `url("https://plataforma.greencardpv.com/solicitudes/${window.subdominio}${window.carpeta}/${datos.url_logo}")`}}></div>
                        )
                    }
                })}
                <div className="containerHero">
                    <div className="slogan" id="slogan" style={{ fontFamily: props.data.fuenteSlogan }}></div>
                    <Timer fechaEvento={props.data.FechaEvento}/>
                    <div className="vistas" style={{ fontFamily: props.data.Fuente }}>
                        <i className="fas fa-eye"></i><strong> {props.data.Vistas}</strong>
                    </div>
                </div>
                <div className="degradado" style={{ background: `linear-gradient(rgba(255,255,255,0) ${props.data.porcentajeDegradado}%, #${props.data.ColorDegradado})` }}></div>
            </div>
            <div className="section" id="Section2" style={{ fontFamily: props.data.Fuente }}>
                <div className="NavbarContact" id="NavbarContact">
                    {props.data.TotalSocial.map(datos => {
                        if (datos.icono === 'fas fa-phone') {
                            return (
                                <a key={datos} href={`tel:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'fab fa-whatsapp') {
                            return (
                                <a key={datos} href={`https://wa.me/${datos.link}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'far fa-envelope') {
                            return (
                                <a key={datos} href={`mailto:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'fas fa-info' || datos.icono === 'far fa-calendar-check') {
                            
                        }
                        else {
                            return (
                                <a key={datos} href={`${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }
                    })}
                    {props.data.TotalService!=0 && props.data.InputInvitados!=0 && Object.values(props.data.TotalService).map(serviceExtra => {
                        if(serviceExtra.tipo_servicio==1){
                            return (
                                <a key={serviceExtra} href={`#!`} onClick={() => handleService(serviceExtra)} className="contactIcon"><i className={`${serviceExtra.icono}`}></i></a>
                            )
                        }
                    })}
                    <a href="" onClick={(eve) => handleShare(eve, `${window.protocoloNoSSL}${window.subdominio}.greencardpv.com`)} className="contactIcon"><i className="fas fa-share-alt"></i></a>
                </div>
                <div className="categorias">
                    <div className="containerCategorias">
                        <div className="serviceTag">
                            <div className="degradadoTag" style={{backgroundColor:props.data.BgSection2}}></div>
                            <div className="ContectService">
                            {props.data.TotalService!=0 && <article style={{fontFamily:props.data.TotalService[0].fuente_titulo,fontSize:props.data.TotalService[0].fuente_modal,color:"#fff"}} dangerouslySetInnerHTML={{__html: props.data.TotalService[0].descripcion_completa}}>
                            
                            </article>}
                            {props.data.InputInvitados==1 && <div className="sectionButtons">
                                <div className="llamadoAccion">
                                    <a className="containerActionButton" href="#!" onClick={() => handleService(1)}>
                                        <i class="far fa-calendar-check"></i>&nbsp;&nbsp;Confirmar mi asistencia
                                    </a>
                                </div>
                            </div>}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {props.data.TotalSlider!=0 && props.data.TotalSlider.filter(gallery => gallery.tipo === 2) && <div className="section" id="Section3">
                <font style={{ fontFamily: props.data.TotalService[0].fuente_titulo, fontSize: `2.5rem`, color: `#fff` }}>{props.data.TotalService[0].nombre}</font>
                <div className="feed">
                    <div className="containerFeed">
                        {props.data.TotalSlider.filter(gallery => gallery.tipo === 2).map(img => { 
                            return (
                                <img key={img} className="feedImagen" src={`https://plataforma.greencardpv.com/solicitudes/${window.subdominio}${window.carpeta}/${img.url_logo}`} alt="" />
                            )
                        })}
                    </div>
                </div>
            </div>
            }
            <div className="contentMedia">
                <div className="socialMedia">
                    {props.data.TotalSocial.map(datos => {
                        if (datos.icono === 'fas fa-phone') {
                            return (
                                <a key={datos} href={`tel:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'fab fa-whatsapp') {
                            return (
                                <a key={datos} href={`https://wa.me/${datos.link}?text=Excelente%20día,%20vengo%20de%20tu%20*Greencardpv*`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'far fa-envelope') {
                            return (
                                <a key={datos} href={`mailto:${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }else
                        if (datos.icono === 'fas fa-info' || datos.icono === 'far fa-calendar-check') {
                            
                        }
                        else {
                            return (
                                <a key={datos} href={`${datos.link}`} className="contactIcon"><i className={`${datos.icono}`}></i></a>
                            )
                        }
                    })}
                    {props.data.TotalService!=0 && props.data.InputInvitados!=0 && Object.values(props.data.TotalService).map(serviceExtra => {
                        if(serviceExtra.tipo_servicio==1){
                            return (
                                <a key={serviceExtra} href={`#!`} onClick={() => handleService(serviceExtra)} className="contactIcon"><i className={`${serviceExtra.icono}`}></i></a>
                            )
                        }
                    })}
                    <a href="" onClick={(eve) => handleShare(eve, `https://${props.data.Subdominio}.greencardpv.com/`)} className="socialIcon"><i className="fas fa-share-alt contactIcon"></i></a>
                </div>
                <div className="greenCard">
                    <strong style={{ fontFamily: props.data.Fuente }}>{window.subdominio}.greencardpv.com</strong>
                </div>
            </div>
            <Modal modal={modal} data={data} function={setModal} />
        </div>
    )
}
const PropsStore = state => {
    return {
        data: state.dataPlantilla.data
    }
}

export default connect(PropsStore, null)(Home)