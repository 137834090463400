import React, { useState, useEffect } from 'react'
import './estilosModal.css'
import {connect} from 'react-redux'
function Modal(props) {
    console.log(props);
    const [show, toggleShow] = React.useState(false);

    useEffect(() => {
        if (props.data.subservice) {
            if ( props.data.subservice.find(service => service.tipo === "1")) {
                const value = props.data.subservice.find(service => service.tipo === "1")
                document.getElementById('incluye').innerHTML=value.descripcion
            }
        }

    }, [props.data])
    if (props.modal === true) {

        const Incluye = () => {
            document.getElementById('incluye').classList.toggle('hidden')
        }
        const descargarPdf = (ruta) => {
            window.open(``)
        }

        const guardaInvitacion = () => {
            const nombre = document.getElementById('familiaCelebrate').value;
            const asistencia = document.getElementById('asistenciaCelebrate').value;
            const mensaje = document.getElementById('mensajeCelebrate').value;
            const adultos = document.getElementById('adultosCelebrate').value;
            const ninos = document.getElementById('ninosCelebrate').value;
            //const infantes = document.getElementById('infantesCelebrate').value;
            const whatsapp = document.getElementById('whatsappCelebrate').value;
            const telefono = document.getElementById('telefonoCelebrate').value;
            if(nombre){
                if(asistencia){
                    if(whatsapp || telefono){
                        if(adultos!="" || ninos!=""){
                            //Guarda información con fetch
                            let formData = new FormData();
                            formData.append('Operacion',"Asistencia");
                            formData.append('nombre',nombre);
                            formData.append('asistencia',asistencia);
                            formData.append('mensaje',mensaje);
                            formData.append('adultos',adultos);
                            formData.append('ninos',ninos);
                            formData.append('infantes',0);
                            formData.append('whatsapp',whatsapp);
                            formData.append('telefono',telefono);
                            formData.append('subdominio',window.subdominio);
                            fetch("https://plataforma.greencardpv.com/api/controller/guardarDatosCelebrate.php", {
                                method: 'POST',
                                body: formData,
                                mode: 'cors',
                            })
                            .then(response => response.json())
                            .then(data => {
                                console.log(data);
                                if(data.Exito==1){
                                    alert(data.Mensaje);
                                    document.getElementById('familiaCelebrate').value="";
                                    document.getElementById('asistenciaCelebrate').value="";
                                    document.getElementById('mensajeCelebrate').value="";
                                    document.getElementById('adultosCelebrate').value="";
                                    document.getElementById('ninosCelebrate').value="";
                                    //document.getElementById('infantesCelebrate').value="";
                                    document.getElementById('whatsappCelebrate').value="";
                                    document.getElementById('telefonoCelebrate').value="";
                                }else{
                                    alert(data.Mensaje);
                                }
                            });
                        }else{alert("Ingrese al menos un adulto o nino")}
                    }else{alert("Ingrese al menos el whatsapp o teléfono para confirmar la asistencia")}
                }else{alert("Selecciona tu asistencia")}
            }else{alert("Ingresa el nombre de la familia")}
        }

        

        return (
            <div className="Modal" style={{ fontFamily: props.Plantilla.FuenteContenido }}>
                <div className="ModalContent">
                    <button className="buttonClose" onClick={() => props.function(false)}>x</button>
                    {props.Plantilla.InputInvitados==1 && <div><label for="familiaCelebrate">Nombre:</label><br/>
                        <input type="text" id="familiaCelebrate" name="familiaCelebrate" /><br/>
                        <label for="asistenciaCelebrate">Asistencia:</label><br/>
                        <select id="asistenciaCelebrate" name="asistenciaCelebrate">
                            <option value="">Selecciona tu asistencia:</option>
                            <option value="1">SI</option>
                            <option value="2">NO</option>
                            <option value="3">TAL VEZ</option>
                        </select><br/>
                        <label for="whatsappCelebrate">Whatsapp:</label><br/>
                        <input type="number" id="whatsappCelebrate" name="whatsappCelebrate" /><br/>
                        <label for="telefonoCelebrate">Teléfono:</label><br/>
                        <input type="number" id="telefonoCelebrate" name="telefonoCelebrate" /><br/>
                        <label for="adultosCelebrate">Adultos:</label><br/>
                        <input type="number" id="adultosCelebrate" name="adultosCelebrate" /><br/>
                        <label for="ninosCelebrate">Niños:</label><br/>
                        <input type="number" id="ninosCelebrate" name="ninosCelebrate" /><br/>
                        {/*<label for="infantesCelebrate">Infantes:</label><br/>
                        <input type="number" id="infantesCelebrate" name="infantesCelebrate" /><br/>*/}
                        <label for="mensajeCelebrate">Mensaje:</label><br/>
                        <textarea name="mensajeCelebrate" id="mensajeCelebrate" rows="4"></textarea>
                        <div className="sectionButtonsModal">
                            <div className="llamadoAccion">
                                <a className="containerAction" href="#" onClick={() => guardaInvitacion()}>
                                    <i class="far fa-paper-plane"></i>&nbsp;&nbsp;Enviar
                                </a>
                            </div>
                        </div>
                        </div>
                    }
                </div>
                <div className="ModalCover" onClick={() => props.function(false)}></div>
            </div >
        )
    } else {
        return (false)
    }
}
const PropsStore = state => {
    return {
        Plantilla:state.dataPlantilla.data
    }
}

export default connect(PropsStore,null)(Modal)